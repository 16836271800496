.request-header {
  display: flex;
  padding: 15px;
  .overview {
    flex: 1;
    .title {
      font-size: 14px;
      color: #000;
      font-weight: 500;
    }
  }
}

.status-requests {
  border-radius: 8px;

  // color: #a8cf45;
  padding: 1px 5px;
  font-size: 12px;
  display: flex;
}

.square-dot {
  width: 5px;
  height: 5px;
  // background: #a8cf45;
  position: relative;
  top: 6px;
  border-radius: 50%;
}

.request-offcanvas {
  width: 600px !important;
  right: -10px !important;
}

.status-message-oofcanvas {

  border-radius: 5px;
  display: flex;
  padding: 1rem;
  .status-label {
    .title {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.patient-details-bg {
  background: #162037;
}

.discount-details-bg {
  border: 1px solid #e0e6f1;
  color: #000;
}

.patient-details {
  border-radius: 5px;
  padding: 10px 8px;
  height: 11rem;

  .patient-label {
    display: flex;
    .label {
      flex: 1;
      text-align: left;
      // color: #EFF3F9;
      font-weight: 300;
      font-size: 12px;
    }
    .name {
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.total-amount-discount {
  display: flex;
  background: #a8cf45;
  padding: 1rem;
  border-radius: 5px;
  .total-amount-label {
    color: #3b500c;
  }
}

.create-user-button-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.user-profile-image-container {
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #efefef;
  justify-content: center;
  display: flex;
  align-items: center;
}

.email-phone-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  h4 {
    font-size: 13px;
  }
}

.button-user-section {
  display: flex;
  column-gap: 10px;
  .active-button {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
}
