@import "variables.scss";
.menu-container {
  cursor: pointer;
  .menu-list {
    padding: 10px 1rem;
    color: #000;
    span {
      padding-left: 8px;
    }
  }
  .active-menu {
    background-color: $secondary-color !important;
    border-radius: 5px;
    color: #ffff !important;
    padding: 10px 1rem;
    .menu-link {
      color: #ffff !important;
    }
    span {
      padding-left: 8px;
    }
  }
}

.profile-dropdown-list {
  position: absolute;
  background: #fff;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 4px 10px 0px #0000001f;
  padding: 5px;
  display: grid;
  border-radius: 5px;
  right: 0.1rem;
  top: 3.5rem;
  z-index: 10;
  span {
    padding: 8px 2rem;
  }
}

.invert-item {
  transform: scale(-1);
}

.export-button {
  border: 1px solid #eff3f9;
  color: #000;
  cursor: pointer;
  &:hover {
    border: 1px solid #eff3f9;
    color: #000;
  }
}

.status_dropdown {
  position: relative;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  select {
    background: #e7f6fc !important;
  }
  .form-select {
    background: #e7f6fc;
    color: #000;
  }
}

.file-container {
  border: 1px solid #eff3f9;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  p {
    color: #ccc;
    font-size: 12px;
    position: relative;
    top: 7px;
  }
}
.info-message {
  color: #0172cb;
}

.user-image-section {
  border-radius: 5px;
  background: #f8f9f9;
  padding: 10px;
  display: flex;
  justify-content: center;
  .user-image {
    background: #787e8b;
    padding: 10px;
  }
}

.profile-header-media {
  padding: 8px 10px;
  margin-right: 7px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  width: 4rem;
  height: 4rem;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
}

.user-profile-section {
  align-items: center;
  display: grid;
  .name {
    position: relative;
    top: 1.5rem;
    text-align: center;
  }
  .email {
    position: relative;
    top: 1rem;
  }
}

.verification-form-container {
  padding: 0 6rem;
}

.forgot-description {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}
.forgot-email {
  font-size: 13px;
  color: #0ba0dc;
  font-weight: 500;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

.main-page-container {
  width: calc(100% - 250px);
}

.navbar-header {
  padding: 0rem;
  background: var(--headerbg);
  z-index: 3;
  height: 70px;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 10px 0px;
  width: calc(100% - 250px);
  padding-top: 0rem;
  transition: all 0.2s ease;
  display: flex;
  justify-content: end;
  padding-right: 1rem;
}

.header-right {
  display: flex;
}

.error-message {
  color: #ca5858;
}

.eye-icon-position {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.request-status-button {
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
